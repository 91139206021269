const theme = {
  colors: {
    primary: '#a18e6c',
    primaryHover: '#cccac6',
    background: '#fffdfa',
    darkBackground: '#191919',
    text: '#a18e6c',
    darkText: '#a18e6c'
  },
};

export default theme;
