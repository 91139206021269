import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { FaBars } from "react-icons/fa6";
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import LanguageToggle from './LanguageToggle';
import DarkModeToggle from './DarkModeToggle'; // Import the new DarkModeToggle component

const HamburgerIcon = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  z-index: 1001;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  position: fixed;
  top: ${({ open }) => (open ? '0' : '-100%')};
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme, $isDarkMode }) => ($isDarkMode ? theme.colors.darkBackground : theme.colors.background)};
  flex-direction: column;
  justify-content: center;
  z-index: 999;
  transition: background-color 0.7s ease-in-out, top 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: ${({ open }) => (open ? '1' : '0')};
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
`;

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -5rem;

  li {
    margin: 1rem 0;

    a {
      text-decoration: none;
      font-size: 1.4rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.primary};
      transition: color 0.3s;
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.colors.primaryHover};
      }
    }
  }
`;

const Header = ({ isDarkMode, toggleDarkMode }) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [menuOpen]);

  return (
    <>
      <HamburgerIcon onClick={toggleMenu}>
        {menuOpen ? <FaTimes /> : <FaBars />}
      </HamburgerIcon>
      <Nav open={menuOpen} $isDarkMode={isDarkMode}>
        <NavLinks>
          <li><Link to="home" smooth={true} duration={500} onClick={toggleMenu}>{t('header.home')}</Link></li>
          <li><Link to="invitation" smooth={true} duration={500} onClick={toggleMenu}>{t('header.invitation')}</Link></li>
          <li><Link to="schedule" smooth={true} duration={500} onClick={toggleMenu}>{t('header.schedule')}</Link></li>
          <li><Link to="detail" smooth={true} duration={500} onClick={toggleMenu}>{t('header.detail')}</Link></li>
          <li><Link to="gallery" smooth={true} duration={500} onClick={toggleMenu}>{t('header.about')}</Link></li>
          <LanguageToggle isDarkMode={isDarkMode} />
          <DarkModeToggle isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
        </NavLinks>
      </Nav>
    </>
  );
};

export default Header;
