import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Gallery from './components/Gallery';
import Schedule from './components/Schedule';
import Invitation from './components/Invitation';
import Detail from './components/Detail';
import AccessCode from './components/AccessCode';
import theme from './styles/theme';
import GlobalStyles from './styles/GlobalStyles'; // Correct import
import './i18n';
import { FiMinus } from "react-icons/fi";

function App() {
  const [accessGranted, setAccessGranted] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const bypassAccessCode = process.env.NODE_ENV === 'development' && process.env.REACT_APP_BYPASS_ACCESS_CODE === 'true';

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles $isDarkMode={isDarkMode} />
      <div className="App">
        {(bypassAccessCode || accessGranted) ? (
          <>
            <Header isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
            <main>
              <Home id="home" />
              <Invitation id="invitation" />
              <FiMinus/>
              <Schedule id="schedule" />
              <FiMinus/>
              <Detail id="detail" />
              <Gallery id="gallery" />
            </main>
          </>
        ) : (
          <AccessCode onAccessGranted={() => setAccessGranted(true)} />
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
