import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LanguageToggle from './LanguageToggle';
import { useTranslation } from 'react-i18next';

const AccessCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const AccessCodeInput = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
`;

const SubmitButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 0.9rem;
  margin-top: 1rem;
`;

const AccessCodePage = ({ onAccessGranted }) => {
  const [accessCode, setAccessCode] = useState('');
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    setAccessCode(e.target.value);
    setError(false); // Reset error when input changes
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const storedAccessCode = process.env.REACT_APP_ACCESS_CODE;
    if (accessCode === storedAccessCode) {
      onAccessGranted();
      window.scrollTo(0, 0); // Scroll to top after successful submission
    } else {
      setError(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError(false); // Clear error after 3 seconds
      }, 3000);
    }

    return () => clearTimeout(timer); // Cleanup timer on unmount or state change
  }, [error]);

  return (
    <AccessCodeContainer>
      <LanguageToggle />
      <h2>{t('accessCode.title')}</h2>
      <form onSubmit={handleSubmit}>
        <AccessCodeInput
          type="password"
          value={accessCode}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder={t('accessCode.placeholder')}
        />
        <SubmitButton type="submit">{t('accessCode.submit')}</SubmitButton>
      </form>
      {error && (
        <ErrorMessage>
          {t('accessCode.errorMessage')}
        </ErrorMessage>
      )}
    </AccessCodeContainer>
  );
};

export default AccessCodePage;
