import React from 'react';
import styled from 'styled-components';
import { IoSunnySharp, IoMoonSharp } from "react-icons/io5";

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

const ToggleLabel = styled.label`
  position: relative;
  width: 55px;
  height: 30px;
  display: inline-block;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &:checked + span::before {
    transform: translateX(25px);
  }
`;

const Slider = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  font-size: 1rem;
  color: black;
  transition: opacity 0.3s;

  ${({ $visible }) => $visible === 'sun' && `
    left: 15px;
  `}

  ${({ $visible }) => $visible === 'moon' && `
    left: calc(100% - 14px);
  `}
`;

const DarkModeToggle = ({ isDarkMode, toggleDarkMode }) => {
  return (
    <ToggleContainer>
      <ToggleLabel>
        <ToggleInput type="checkbox" checked={isDarkMode} onChange={toggleDarkMode} />
        <Slider>
          <IconWrapper $visible={isDarkMode ? 'moon' : 'sun'}>
            {isDarkMode ? <IoMoonSharp /> : <IoSunnySharp />}
          </IconWrapper>
        </Slider>
      </ToggleLabel>
    </ToggleContainer>
  );
};

export default DarkModeToggle;
