import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DoubleHappiness from '../assets/props/double-happiness.png';
import FlowersLeft from '../assets/props/flowers-left.png';
import FlowersRight from '../assets/props/flowers-right.png';
import RanasSlottOutline from '../assets/props/ranas-slott-outline.png';
import Typography from '../styles/Typography';

const InvitationSection = styled.section`
  width: 100%;
  padding: 3rem 2rem 1rem 2rem;
  text-align: center;
  position: relative;
  overflow-x: hidden;
`;

const Decorations = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const FlowersLeftImage = styled.img`
  position: absolute;
  right: 50%;
  width: 60%;
  max-width: 350px;
  height: auto;
  transform: translateX(0);
  z-index: 1;

  // @media (max-width: 768px) {
  //   width: 60%;
  //   // max-width: none;
  // }
`;

const FlowersRightImage = styled.img`
  position: absolute;
  left: 50%;
  width: 60%;
  max-width: 350px;
  height: auto;
  transform: translateX(9px);
  z-index: 1;

  // @media (max-width: 768px) {
  //   width: 60%;
  //   // max-width: none;
  // }
`;

const DoubleHappinessImage = styled.img`
  width: 45%;
  height: auto;
  z-index: 2;
  max-width: 180px;

  // @media (max-width: 768px) {
  //   width: 35%;
  //   max-width: 200px;
  // }
`;

const CastleImage = styled.img`
  width: 100%;
  max-width: 500px;
  height: auto;
  padding: 1rem 0 1rem 0;
`;

// const ExternalLinksText = styled.a`
//   font-family: 'Cormorant SC', serif;
//   font-size: ${({ fontSize }) => fontSize || '1rem'};
//   font-weight: normal;
//   color: #a18e6c;
//   margin: 0.5rem 0;
//   display: inline-flex;
//   align-items: center;
//   padding: 0.3rem 1rem;
//   border: 1px solid;
//   border-radius: 5px;
//   transition: background-color 0.3s;

//   &:hover {
//     background-color: #f3f0ea;
//   }
// `;

// const MapPinIcon = styled(PiMapPinLine)`
//   margin-right: 6px;
//   margin-bottom: -1px;
//   font-size: 1.2rem;
// `;

// const EnvelopeIcon = styled(SlEnvolopeLetter)`
//   margin-right: 9px;
//   margin-bottom: -1px;
//   font-size: 1rem;
// `;

const Invitation = () => {
  const { t } = useTranslation();
  return (
    <InvitationSection id="invitation">
      <Decorations>
        <FlowersLeftImage src={FlowersLeft} alt="Flowers Left" />
        <DoubleHappinessImage src={DoubleHappiness} alt="Double Happiness" />
        <FlowersRightImage src={FlowersRight} alt="Flowers Right" />
      </Decorations>
      <Typography $variant="tangerine" fontSize="2.2rem" $margin="1.5rem 0">{t('invitation.gettingMarried')}</Typography>
      <Typography $variant="cormorant-sc" fontSize="2rem" $margin="1.5rem 0">IDA EJNESTRAND<br/>& KYLE CHAN</Typography>
      <Typography $variant="tangerine" fontSize="2rem" $margin="1.5rem 0">{t('invitation.youAreInvited')}</Typography>
      <Typography $variant="cormorant-sc" fontSize="1.2rem" $margin="1.5rem 0">{t('invitation.datePartOne')}<br/>{t('invitation.datePartTwo')}</Typography>
      <Typography $variant="cormorant-sc" fontSize="1.2rem" $margin="1.5rem 0">{t('invitation.locationName')}</Typography>
      <CastleImage src={RanasSlottOutline} alt={t('invitation.locationName')} />
      <br/>
      <Typography $variant="cormorant-garamond" fontSize="1.2rem" $margin="1.5rem 0">{t('invitation.messagePartOne')}</Typography>
      <Typography $variant="cormorant-garamond" fontSize="1.2rem" $margin="1.5rem 0">{t('invitation.messagePartTwo')}</Typography>
      <Typography $variant="cormorant-garamond" fontSize="1.2rem" $margin="1.5rem 0">{t('invitation.messagePartThree')}</Typography>
      <Typography $variant="tangerine" fontSize="2.2rem" fontWeight='400' $margin="0">{t('invitation.messagePartFour')}</Typography>
      <Typography $variant="tangerine" fontSize="2.2rem" fontWeight='400' $margin="0 0 1rem 0">{t('invitation.messagePartFive')}</Typography>
      {/* <ExternalLinksText fontSize="1rem"
        href={t('invitation.mapURL')} 
        target="_blank" 
        rel="noopener noreferrer"
        aria-label={t('invitation.locationName')}
      >
        <MapPinIcon />
        {t('invitation.locationName')}
      </ExternalLinksText>
      <br/>
      <ExternalLinksText fontSize="1rem"
        href={t('invitation.rsvpURL')}
        target="_blank" 
        rel="noopener noreferrer"
        aria-label={t('invitation.rsvp')}
      >
        <EnvelopeIcon />
        {t('invitation.rsvp')}
      </ExternalLinksText> */}
    </InvitationSection>
  );
};

export default Invitation;
