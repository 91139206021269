import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Cormorant SC', 'Helvetica Neue', Arial, sans-serif;
    background-color: ${({ theme, $isDarkMode }) => ($isDarkMode ? theme.colors.darkBackground : theme.colors.background)};
    color: ${({ theme, $isDarkMode }) => ($isDarkMode ? theme.colors.darkText : theme.colors.text)};
    padding: 0;
    margin: 0;
    transition: background-color 0.3s, color 0.3s;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 1rem 0;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }

  ul {
    list-style: none;
  }

  button {
    background: ${({ theme }) => theme.colors.primary};
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: ${({ theme }) => theme.colors.primaryHover};
    }
  }
`;

export default GlobalStyles;
