import React from 'react';
import styled from 'styled-components';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { styled as MuiStyled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import RanasSlottImage from '../assets/props/ranas-slott.jpg';
import Typography from '../styles/Typography';

const ScheduleSection = styled.section`
  width: 100%;
  max-width: 600px;
  padding: 1rem 0;
  position: relative;
  overflow-x: hidden;
  margin: 0 auto;
`;

const SectionImage = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  object-position: 50% 0%;
  padding: 1rem 0;
`;


const StyledTimelineElement = MuiStyled('div')`
  background-color: #a18e6c;
`;

const Schedule = () => {
  const { t } = useTranslation();
  const fullSchedule = t('schedule.fullSchedule', { returnObjects: true });

  return (
    <ScheduleSection id="schedule">
      <SectionImage src={RanasSlottImage} alt={t('invitation.locationName')} />
      <Typography $variant="cormorant-sc" fontSize="2.2rem">
        {t('schedule.title')}
      </Typography>
      {fullSchedule.map((day, dayIndex) => (
        <div key={dayIndex}>
          <Typography $variant="tangerine" fontSize="2.5rem">
            {day.date}
          </Typography>
          <Timeline position="alternate" sx={{ px: '10px' }}>
            {day.events.map((event, eventIndex) => (
              <TimelineItem key={eventIndex}>
                <TimelineOppositeContent sx={{ py: '2px', px: '15px', m: 'auto 0' }}
                  align="right"
                  variant="body2"
                >
                  <Typography $variant="cormorant-sc" fontSize="1rem" $margin="0">
                    {event.time}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <StyledTimelineElement as={TimelineConnector} />
                  <StyledTimelineElement as={TimelineDot} />
                  <StyledTimelineElement as={TimelineConnector} />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '2px', px: '15px' }}>
                  <Typography $variant="cormorant-sc" fontSize="1.35rem" $margin="0">
                    {event.name}
                  </Typography>
                  <Typography $variant="cormorant-garamond" fontSize="0.85rem" $margin="0">
                    {event.description}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </div>
      ))}
      <br/>
    </ScheduleSection>
  );
};

export default Schedule;
