import React from 'react';
import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import HomeImage from '../assets/props/home.jpeg';

const HomeSection = styled.section`
  width: 100%;
  height: 100vh;
  background: url(${HomeImage}) no-repeat;
  background-size: cover;
  background-position: center top 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ScrollDownIndicator = styled.div`
  position: absolute;
  bottom: 110px;
  text-align: center;
  font-size: 2rem;
  color: white;
  z-index: 100;
  animation: pulse 1.2s infinite;
  cursor: pointer;

  @keyframes pulse {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    50% {
      transform: translateY(10px);
      opacity: 0.2;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const WeddingDateOverlay = styled.div`
  font-family: 'Tangerine', serif;
  font-size: 4rem;
  font-weight: normal;
  position: absolute;
  bottom: 160px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 25px 10px;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
`;

const Home = () => {
  const { t } = useTranslation();

  const scrollToDetailSection = () => {
    const aboutSection = document.getElementById('invitation');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <HomeSection id="home">
      <WeddingDateOverlay>
        {t('home.weddingDate')}
      </WeddingDateOverlay>
      <ScrollDownIndicator onClick={scrollToDetailSection}>
        <FaChevronDown />
      </ScrollDownIndicator>
    </HomeSection>
  );
};

export default Home;
