import styled from 'styled-components';

const Typography = styled.h2`
  font-family: ${({ $variant }) => {
    switch ($variant) {
      case 'tangerine':
        return "'Tangerine', serif";
      case 'cormorant-sc':
        return "'Cormorant SC', serif";
      case 'cormorant-garamond':
        return "'Cormorant Garamond', serif";
      default:
        return "'Helvetica Neue', Arial, sans-serif";
    }
  }};
  font-size: ${({ fontSize }) => fontSize || '2rem'};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  color: ${({ color, theme }) => color || theme.colors.primary};
  margin: ${({ $margin }) => $margin || '1rem 0'};
`;

export default Typography;
