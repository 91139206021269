import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';

// Import the SVGs from the src/assets/flags directory
import USFlag from '../assets/flags/us.svg';
import HKFlag from '../assets/flags/hk.svg';
import SEFlag from '../assets/flags/se.svg';
import GRFlag from '../assets/flags/gr.svg';

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  margin: 1rem;
`;

const DropdownButton = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0.5rem 1rem;
  font-family: 'Cormorant SC', serif;
  font-size: 1.2rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }
`;

const DropdownContent = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;

  button {
    background-color: white;
    color: black;
    padding: 0.5rem 1rem;
    width: 100%;
    border: none;
    text-align: left;
    cursor: pointer;
    // font-family: 'Cormorant SC', serif;
    font-size: 1rem;
    display: flex;
    align-items: center;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
      color: white;
    }

    &:focus {
      outline: none;
    }

    img {
      width: 20px;
      height: auto;
      margin-right: 0.5rem;
    }
  }
`;

const flagMap = {
  en: USFlag,
  zh: HKFlag,
  sv: SEFlag,
  el: GRFlag
};

const LanguageToggle = ({ isDarkMode }) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const dropdownRef = useRef(null);

  const languages = useMemo(() => ({
    en: 'English',
    zh: '中文',
    sv: 'Svenska',
    el: 'Ελληνικά'
  }), []);

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    setOpen(false); // Close dropdown after selecting a language
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Dropdown ref={dropdownRef}>
      <DropdownButton onClick={toggleDropdown}>
        <img src={flagMap[selectedLanguage]} alt={languages[selectedLanguage]} style={{ width: '20px', height: 'auto', marginRight: '0.5rem' }} />
        {languages[selectedLanguage]}
        <FaChevronDown style={{ marginLeft: '0.5rem' }} />
      </DropdownButton>
      <DropdownContent open={open}>
        {Object.keys(languages).map((lng) => (
          <button key={lng} onClick={() => changeLanguage(lng)}>
            <img src={flagMap[lng]} alt={languages[lng]} /> {languages[lng]}
          </button>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};

export default LanguageToggle;
