import React, { useState } from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useTranslation } from 'react-i18next';
import Typography from '../styles/Typography';

const GallerySection = styled.section`
  padding: 1rem 1rem;
  font-family: 'Cormorant SC', serif;
  font-size: 2rem;
  font-weight: normal;
  color: #a18e6c;
  text-align: center;
  background-color: #191919;

  .image-gallery {
    max-width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .image-gallery-slide-wrapper {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .image-gallery-slide img {
    height: 60vh;
    max-height: 100%;
    max-width: 99%;
    object-fit: contain;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .image-gallery-icon {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    opacity: 1;
    z-index: 100;
  }

  .image-gallery-icon:hover {
    opacity: 1;
  }

  .image-gallery-thumbnails-wrapper {
    display: none;
  }

  .image-gallery-thumbnails {
    display: flex;
    flex-wrap: wrap;
  }
`;

const ThumbnailsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 25px 0 10px 0;
`;

const StyledThumbnail = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 3px solid transparent;

  &:hover {
    opacity: 0.7;
  }

  ${({ isActive }) =>
    isActive &&
    `
    border-color: #a18e6c;
  `}
`;

// Wrapper component to ensure `isActive` is not passed to the DOM
const Thumbnail = ({ isActive, ...props }) => {
  return <StyledThumbnail {...props} />;
};


// const TextDescription = styled.p`
//   font-family: 'Cormorant SC', serif;
//   font-size: 1.5rem;
//   margin: 0;
//   padding: 0.5rem 0;
//   line-height: 0.5;
// `;

// const DateDescription = styled.p`
//   font-family: 'Tangerine', serif;
//   font-size: 2rem;
//   margin: 0;
//   padding: 0.5rem 0;
// `;

const Gallery = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);

  const galleryImages = t('gallery.images', { returnObjects: true });

  const images = galleryImages.map((fileName, index) => {
    const importedImage = require(`../assets/gallery/${fileName}`);
    return {
      original: importedImage,
      thumbnail: importedImage,
      originalAlt: `image${index}`,
      thumbnailAlt: `image${index}`,
      textDescription: fileName,
      dateDescription: fileName,
    };
  });

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <GallerySection id="gallery">
      <Typography $variant="cormorant-sc" fontSize="2.2rem">
        {t('gallery.title')}
        {/* <span style={{ fontSize: '1.2rem', marginLeft: '0.5rem' }}>
          {t('gallery.titleExtended')}
        </span> */}
      </Typography>
      <ImageGallery
        items={images}
        startIndex={currentIndex}
        onSlide={handleSlideChange}
        showNav
        showBullets={false}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
        slideDuration={200}
        lazyLoad={true}
      />
      {/* <TextDescription>{images[currentIndex].textDescription}</TextDescription> */}
      {/* <DateDescription key={currentIndex}>{images[currentIndex].dateDescription}</DateDescription> */}
      <ThumbnailsGrid>
        {images.map((image, index) => (
          <Thumbnail
            key={index}
            src={image.thumbnail}
            alt={image.thumbnailAlt}
            isActive={index === currentIndex}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </ThumbnailsGrid>
    </GallerySection>
  );
};

export default Gallery;
